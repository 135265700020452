<script>
import { SimpleBar } from "simplebar-vue3";

import Swal from "sweetalert2";
import i18n from "../i18n";
import { AbilityBuilder, Ability } from "@casl/ability";
import { ABILITY_TOKEN } from "@casl/vue";
import { fetchOUAccess } from "@/helpers/abilities.js";
import { fetchMenus } from "@/helpers/menuApi.js";
// import Notifications from '@/layouts/partials/notifications.vue';
import axios from "axios";
/**
 * Nav-bar Component
 */
export default {
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
      suggested_menu: [],
      otherDocuments: [],
      searchLoading: false,
    };
  },
  components: {
    SimpleBar,
    // Notifications,
  },

  methods: {
    handleOUselect(event) {
      Swal.fire({
        title: "Do you want to proceed?",
        text: "You will be redirected to home page. Any unsaved changes that you made will be discarded.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes!",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          //if confirmed to delete, then delete all selected rows
          this.$store.commit("setActiveOU", event.target.value);
          this.updateAbility();
          this.getOUDetails();
          fetchMenus();
          this.$router.push({ name: "default" });
        }
        this.loadOU();
      });
    },
    async updateAbility() {
      const { can, rules } = new AbilityBuilder(Ability);
      const roles = localStorage.getItem("userRoles")
        ? JSON.parse(localStorage.getItem("userRoles")).map(
            (item) => item.role_id
          )
        : [];
      // const ou = localStorage.getItem("activeOU");roles.includes(1)
      if (roles.includes(1)) {
        can("manage", "all");
      } else {
        const url = `${process.env.VUE_APP_AUTHDOMAIN}/api/v1/me/user-permissions`;
        let permissions = [];
        await axios
          .post(url, { roles: roles, ou_code: this.ouCode })
          .then((result) => {
            if (result.data.data) {
              permissions = result.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            if (permissions.length > 0) {
              permissions.forEach((item) => {
                if (item.module) {
                  can(
                    item.permission.code,
                    item.module ? item.module.code : ""
                  );
                }
              });
            }
          });
      }
      this.$store.commit("setRules", rules);
      this.$ability.update(rules);
    },

    async getOUDetails() {
      const url = `${process.env.VUE_APP_AUTHDOMAIN}/api/v1/company/${this.ouCode}`;
      await axios
        .get(url)
        .then((result) => {
          if (result.data.data) {
            localStorage.setItem("ouLogo", result.data.data.header_logo);
            this.$emit("ouSelected", result.data.data.header_logo);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    loadOU() {
      if (
        localStorage.getItem("ouAccess") == null ||
        localStorage.getItem("ouAccess").length === 0
      ) {
        fetchOUAccess();
      } else {
        this.$store.commit("getOUAccess");
      }
    },
    loadActiveOU() {
      this.$store.commit("getActiveOU");
      // if (localStorage.getItem('activeOU') == null || localStorage.getItem('activeOU').length===0)
      // {
      //     fetchOUAccess();
      // }
      // else
      // {
      // }
    },

    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;
      let layoutType = document.documentElement.getAttribute("data-layout");
      let visiblilityType = document.documentElement.getAttribute(
        "data-sidebar-visibility"
      );
      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
          ? document.body.classList.remove("menu")
          : document.body.classList.add("menu");
      }

      //For collapse vertical menu

      if (
        visiblilityType === "show" &&
        (layoutType === "vertical" || layoutType === "semibox")
      ) {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
            ? document.documentElement.setAttribute("data-sidebar-size", "sm")
            : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    toggleDarkMode() {
      if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
        document.documentElement.setAttribute("data-layout-mode", "light");
      } else {
        document.documentElement.setAttribute("data-layout-mode", "dark");
      }
    },
    initTopbarComponents() {
      // notification messages
      if (document.getElementsByClassName("notification-check")) {
        Array.from(
          document.querySelectorAll(".notification-check input")
        ).forEach(function (element) {
          element.addEventListener("click", function (el) {
            el.target.closest(".notification-item").classList.toggle("active");
          });
        });
      }
    },

    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      let searchTimer;

      searchInput.addEventListener("keyup", () => {
        // Clear any existing timer to avoid multiple API calls
        clearTimeout(searchTimer);

        var inputLength = searchInput.value.length;
        if (inputLength > 2) {
          this.searchLoading = true;
          // Set a timer to wait for a specific delay (e.g., 500 milliseconds) before initiating the search
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
          searchTimer = setTimeout(() => {
            this.initSearch(searchInput.value);
          }, 300); // Adjust the delay as needed
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchOptions.addEventListener("click", () => {
        searchInput.value = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },

    async initSearch(q) {
      const userId = localStorage.getItem("userid");
      const ou = localStorage.getItem("activeOU");
      const url = `${process.env.VUE_APP_AUTHDOMAIN}/api/v1/menu-search`;
      await axios
        .get(url, { params: { query: q, user_id: userId, ou: ou } })
        .then((result) => {
          this.searchLoading = false;
          this.suggested_menu =
            result.data.data.length > 0 ? result.data.data : [];
          this.otherDocuments =
            result.data.otherDocumentsData.length > 0
              ? result.data.otherDocumentsData
              : [];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.searchLoading = false;
        });
    },
  },
  computed: {
    ouAccess() {
      return this.$store.state.ouAccess;
    },
    ouCode() {
      return this.$store.state.activeOU;
    },
    rules() {
      return this.$store.state.rules;
    },
  },

  created() {},
  mounted() {
    this.loadOU();
    this.loadActiveOU();

    document.addEventListener("scroll", function () {
      var pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
          ? pageTopbar.classList.add("topbar-shadow")
          : pageTopbar.classList.remove("topbar-shadow");
      }
    });
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);

    this.isCustomDropdown();
    this.initTopbarComponents();
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header bg-success text-light">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <application-logo />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <!-- <img src="@/assets/images/logo-light.png" alt="" height="17" /> -->
                <application-logo />
              </span>
            </router-link>
          </div>

          <button
            type="button"
            class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <!-- App Search-->
          <form class="app-search d-none d-md-block">
            <div class="position-relative">
              <input
                type="text"
                class="form-control bg-white text-dark"
                style="color: black !important"
                placeholder="Search..."
                autocomplete="off"
                id="search-options"
                value=""
              />
              <span class="mdi mdi-magnify search-widget-icon text-dark"></span>
              <span
                class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                id="search-close-options"
              ></span>
            </div>
            <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
              <b-overlay :show="searchLoading">
                <SimpleBar data-simplebar style="max-height: 320px">
                  <div
                    class="dropdown-header mt-2"
                    v-if="suggested_menu.length > 0"
                  >
                    <h6 class="text-overflow text-muted mb-1 text-uppercase">
                      Menu
                    </h6>
                  </div>
                  <div
                    class="dropdown-header mt-2"
                    v-if="
                      suggested_menu.length == 0 && otherDocuments.length == 0
                    "
                  >
                    <h6
                      class="text-overflow text-muted mb-1 text-uppercase fw-bold"
                    >
                      No result found.
                    </h6>
                  </div>
                  <template
                    v-for="(menu, index) in suggested_menu"
                    :key="index"
                  >
                    <b-link
                      v-if="menu.children.length == 0"
                      class="dropdown-item notify-item"
                      :href="menu.url"
                    >
                      <i
                        :class="menu.icon ?? 'ri-menu-line'"
                        class="align-middle fs-18 text-muted me-2"
                      ></i>
                      <span>{{ menu.name }}</span>
                    </b-link>

                    <template v-else>
                      <b-link
                        class="dropdown-item notify-item"
                        :href="menu.url"
                      >
                        <i
                          :class="menu.icon ?? 'ri-menu-line'"
                          class="align-middle fs-18 text-muted me-2"
                        ></i>
                        <span>{{ menu.name }}</span>
                      </b-link>

                      <template
                        v-for="(child, child_index) in menu.children"
                        :key="child_index"
                      >
                        <b-link
                          v-if="child.children.length == 0"
                          class="dropdown-item notify-item ms-2"
                          :href="child.url"
                        >
                          <i
                            :class="child.icon ?? 'ri-arrow-drop-right-line'"
                            class="align-middle fs-18 text-muted me-2"
                          ></i>
                          <span>{{ child.name }}</span>
                        </b-link>

                        <template v-else>
                          <b-link
                            :href="'#child' + child_index"
                            class="dropdown-item notify-item ms-2"
                          >
                            <i
                              :class="child.icon ?? 'ri-arrow-drop-right-line'"
                              class="align-middle fs-18 text-muted me-2"
                            ></i>
                            {{ child.name }}
                          </b-link>

                          <template
                            v-for="(
                              sub_child, sub_child_index
                            ) in child.children"
                            :key="sub_child_index"
                          >
                            <b-link
                              v-if="sub_child.children.length == 0"
                              class="dropdown-item notify-item ms-4"
                              :href="sub_child.url"
                            >
                              <i
                                :class="
                                  sub_child.icon ?? 'ri-arrow-drop-right-line'
                                "
                                class="align-middle fs-18 text-muted me-2"
                              ></i>
                              <span>{{ sub_child.name }}</span>
                            </b-link>

                            <template v-else>
                              <b-link
                                :href="'#sub_child' + sub_child_index"
                                class="dropdown-item notify-item ms-2"
                              >
                                <i
                                  :class="
                                    sub_child.icon ?? 'ri-arrow-drop-right-line'
                                  "
                                  class="align-middle fs-18 text-muted me-2"
                                ></i>
                                {{ sub_child.name }}
                              </b-link>

                              <template
                                v-for="(
                                  grand_child, grand_child_index
                                ) in sub_child.children"
                                :key="grand_child_index"
                              >
                                <b-link
                                  class="dropdown-item notify-item ms-4"
                                  :href="grand_child.url"
                                >
                                  <i
                                    :class="
                                      grand_child.icon ??
                                      'ri-arrow-drop-right-line'
                                    "
                                    class="align-middle fs-18 text-muted me-2"
                                  ></i>
                                  <span>{{ grand_child.name }}</span>
                                </b-link>
                              </template>
                            </template>
                          </template>
                        </template>
                      </template>
                    </template>
                  </template>

                  <!-- DN CN section -->
                  <template
                    v-for="(doc, groupindex) in otherDocuments"
                    :key="groupindex"
                  >
                    <div class="dropdown-header mt-2" v-if="doc.length > 0">
                      <h6
                        class="text-overflow text-dark mb-1 text-uppercase fw-bolder"
                      >
                        {{ doc[0].doc_type }}
                      </h6>
                    </div>
                    <template
                      v-for="(item, otherDocumentsindex) in doc"
                      :key="otherDocumentsindex"
                    >
                      <b-link
                        class="dropdown-item notify-item ms-2"
                        :href="item.generated_url"
                      >
                        <i
                          class="ri-menu-line align-middle fs-18 text-muted me-2"
                        ></i>
                        <span>{{ item.doc_no }}</span>
                      </b-link>
                    </template>
                  </template>
                </SimpleBar>
              </b-overlay>
            </div>
          </form>
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="bx bx-search fs-22"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <b-button variant="primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </b-button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="ms-1 header-item d-sm-flex">
            <!-- <ouDropdown @change="handleOUselect" v-model="ouCode" /> -->
            <!-- <select
              class="form-select w-xl"
              :readonly="ouAccess.length <= 1"
              @change="handleOUselect"
              v-model="ouCode"
            >
              <option
                v-for="(row, index) in ouAccess"
                :key="index"
                :value="row.ou_code"
              >
                {{ row.ou_name }}
              </option>
            </select> -->
          </div>
          <div class="ms-1 header-item d-none d-sm-flex">
            <b-button
              type="button"
              variant="ghost-secondary"
              class="text-light btn-icon btn-topbar rounded-circle"
              data-toggle="fullscreen"
              @click="initFullScreen"
            >
              <i class="bx bx-fullscreen fs-22"></i>
            </b-button>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <b-button
              type="button"
              variant="ghost-secondary"
              class="text-light btn-icon btn-topbar rounded-circle light-dark-mode"
              @click="toggleDarkMode"
            >
              <i class="bx bx-moon fs-22"></i>
            </b-button>
          </div>
          <!-- <Notifications /> -->

          <div
            class="dropdown ms-sm-3 header-item topbar-user bg-success bg-gradient"
          >
            <button
              type="button"
              class="btn"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="d-flex align-items-center">
                <img
                  class="rounded-circle header-profile-user"
                  src="@/assets/images/users/user-dummy-img.jpg"
                  alt="Header Avatar"
                />
                <span class="text-start ms-xl-2">
                  <span
                    class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                    >{{ user.firstname + " " + user.lastname }}</span
                  >
                  <!-- <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{ user.email }}</span> -->
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- <h6 class="dropdown-header">Welcome Anna!</h6> -->
              <router-link class="dropdown-item" to="/user/profile/"
                ><i
                  class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">Profile</span>
              </router-link>

              <div class="dropdown-divider"></div>
              <b-link class="dropdown-item" href="/logout"
                ><i
                  class="mdi mdi-logout text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle" data-key="t-logout">Logout</span>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
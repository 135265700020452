import axios from "axios";
import store from "@/state/store.js";

export function fetchMenus() {
    const url = `${process.env.VUE_APP_AUTHDOMAIN}/api/v1/me/menu`;
    return axios
        .get(url)
        .then((response) => {
            if (response.data.data) {
                store.commit("setMenus", response.data.data);
            }
        })
        .catch((error) => {
            console.error("Error fetching menu:", error);
            return null;
        });
}

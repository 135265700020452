import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        let toKeep = ['crm_vuex']
        let keyToRemove = [];
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (!toKeep.includes(key)) {
            keyToRemove.push(key)
          }
        }
        keyToRemove.forEach(element => {
          localStorage.removeItem(element)
        });
        store.commit('setUserId', null)
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/account/logout")
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/pages/starter.vue"),
  },

  // Menus
  {
    path: "/maintenance/menu",
    name: "Menus",
    meta: { title: "Menu", authRequired: true },
    component: () => import("../views/maintenance/menus"),
  },
  {
    path: "/maintenance/menu/form",
    name: "AddMenu",
    meta: { title: "Add Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },
  {
    path: "/maintenance/menu/form/:id",
    name: "EditMenu",
    meta: { title: "Edit Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },

  // Customers
  {
    path: "/customers",
    name: "Customers",
    meta: { title: "Customers", authRequired: true },
    component: () => import("../views/customers"),
  },
  {
    path: "/customers/profile/:id",
    name: "CustomerProfile",
    meta: { title: "Customers", authRequired: true },
    component: () => import("../views/customers/form"),
  },
  // Sales Officer
  {
    path: "/user-management/sale_officer",
    name: "SalesOfficer",
    meta: { title: "Sales Officer", authRequired: true },
    component: () => import("../views/user_management/sales_officer/SalesOfficer.vue"),
  },
  // pre enroll customer
  {
    path: "/customers/pre_enroll",
    name: "pre_enroll",
    meta: {
      title: "Pre-Enroll",
      authRequired: true,
    },
    component: () => import("../views/customers/pre_enroll/PreEnroll.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/meeting_schedule/index.vue"),
  },

  // Client Management Accreditataion Template
  {
    path: "/maintenance/accreditation-template",
    name: "AccreditationTemplate",
    meta: { title: "Accreditation Template", authRequired: true },
    component: () => import("../views/maintenance/accreditation-template/index"),
  },

  // Service Capability Evaluation Template
  {
    path: "/maintenance/sce-template",
    name: "SCETemplate",
    meta: { title: "SCE Template", authRequired: true },
    component: () => import("../views/maintenance/sce-template/index"),
  },

  // Form Builder
  {
    path: "/maintenance/form-builder",
    name: "FormBuilder",
    meta: { title: "Form Builder", authRequired: true },
    component: () => import("../views/maintenance/form-builder"),
  },

  {
    path: "/maintenance/form-builder/add",
    name: "AddFormBuilder",
    meta: { title: "Add Form", authRequired: true },
    component: () => import("../views/maintenance/form-builder/form"),
  },

  {
    path: "/maintenance/form-builder/:id",
    name: "EditFormBuilder",
    meta: { title: "Edit Form", authRequired: true },
    component: () => import("../views/maintenance/form-builder/form"),
  },

  {
    path: "/maintenance/form-builder/view/:id",
    name: "ViewFormBuilder",
    meta: { title: "View Form", authRequired: true },
    component: () => import("../views/maintenance/form-builder/view"),
  },

  {
    path: "/response_page/:sales_officer_id/:meeting_schedule_id",
    name: "response_page",
    component: () => import("../views/dashboard/meeting_schedule/responsePage.vue"),
    meta: {
      title: "Response",
      authRequired: false,
    },
  },

  
  {
    path: "/sales_officer/for_proposal",
    name: "for_proposal",
    meta: {
      title: "For Proposal",
      authRequired: true,
    },
    component: () => import("../views/sales_officer/for_proposal/forProposal.vue"),
  },
  
  {
    path: "/project_costing",
    name: "project_costing",
    meta: {
      title: "Project Costing",
      authRequired: true,
    },
    component: () => import("../views/project_costing/index"),
  },

  // Inventory Management
  {
    path: "/inventory-management/project-costing",
    name: "ProjectCosting",
    meta: { title: "Project Costing", authRequired: true },
    component: () => import("../views/inventory-management/project-costing/create-project/index"),
  },
  {
    path: "/inventory-management/dashboard",
    name: "InventoryDashboard",
    meta: { title: "Inventory Dashboard", authRequired: true },
    component: () => import("../views/inventory-management/dashboard/index"),
  },
  {
    path: "/inventory-management/project-costing/categories",
    name: "Categories",
    meta: { title: "Categories", authRequired: true },
    component: () => import("../views/inventory-management/project-costing/categories/index"),
  },
];
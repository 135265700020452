import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";

import '@/helpers/ag-grid-enterprise';
import '@/helpers/ag-grid-enterprise/styles/ag-grid.css';
import '@/helpers/ag-grid-enterprise/styles/ag-theme-alpine.css';

import { AgGridVue } from 'ag-grid-vue3'; 

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import flatPickr from "vue-flatpickr-component";

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';

const ability = new Ability();

import '@/assets/scss/myStyle.css';

import { surveyPlugin } from "survey-vue3-ui";
import { surveyCreatorPlugin } from "survey-creator-vue";

import "survey-core/survey.i18n.js";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();


// Firebase authentication
// import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

// initFirebaseBackend(firebaseConfig);


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createApp(App)
    .use(store)
    .use(router)
    .component('Layout', Layout)
    .component('PageHeader', PageHeader)
    .component('AgGridVue', AgGridVue)
    
    .component(VueFeather.type, VueFeather)
    .component('flatPickr', flatPickr)
     
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)
    .use(abilitiesPlugin, ability, {
        useGlobalProperties: true
    })
    .use(surveyPlugin)
    .use(surveyCreatorPlugin)
    .directive('uppercase', {
        updated(el) {
            el.value = el.value.toUpperCase();
            el.dispatchEvent(new Event('input')); // to trigger v-model update
        }
    })
    .mount('#app');
import { createStore } from "vuex";

import modules from "./modules";
import createPersistedState from 'vuex-persist';

const persistedState = new createPersistedState({
  key:'crm_vuex',
  storage:localStorage,
  reducer:state=> ({
    userId: state.userId,
    salesOfficerDetails: state.salesOfficerDetails,
  })
})

const store = createStore({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
  state: {
    menus: [],
    ouAccess: [],
    userRoles: [],
    activeOU: "",
    userId:null,
    salesOfficerDetails:{},
  },
  actions:{
    setUserId(context,Id){
      context.commit('setUserId',Id)
    },
    setSalesOfficerDetails(context,data){
      context.commit('setSalesOfficerDetails',data)
    },
  },
  mutations: {
    setMenus(state, menus) {
      localStorage.setItem("storedMenu", JSON.stringify(menus));
      state.menus = menus;
    },
    getMenus(state) {
      const storedMenu = localStorage.getItem("storedMenu");
      if (storedMenu) {
        state.menus = JSON.parse(storedMenu);
      }
    },
    setRoles(state, roles) {
      localStorage.setItem("userRoles", JSON.stringify(roles));
      state.userRoles = roles;
    },
    getRoles(state) {
      const storedRoles = localStorage.getItem("userRoles");
      if (storedRoles) {
        state.userRoles = JSON.parse(storedRoles);
      }
    },
    setRules(state, rules) {
      localStorage.setItem("rules", JSON.stringify(rules));
      state.rules = rules;
    },
    getRules(state) {
      const rules = localStorage.getItem("rules");
      if (rules) {
        state.rules = JSON.parse(rules);
      }
    },
    setOUAccess(state, ous) {
      localStorage.setItem("ouAccess", JSON.stringify(ous));
      // console.log(roles);
      state.ouAccess = ous;
    },
    getOUAccess(state) {
      const storedOU = localStorage.getItem("ouAccess");
      if (storedOU) {
        state.ouAccess = JSON.parse(storedOU);
      }
    },
    setActiveOU(state, ou) {
      localStorage.setItem("activeOU", ou);
      state.activeOU = ou;
    },
    getActiveOU(state) {
      const storedOU = localStorage.getItem("activeOU");
      if (storedOU) {
        state.activeOU = storedOU;
      }
    },
    setPermissions(state, permissions) {
      localStorage.setItem("permissions", JSON.stringify(permissions));
      // console.log(roles);
      state.permissions = permissions;
    },
    getPermissions(state) {
      const permissions = localStorage.getItem("permissions");
      if (permissions) {
        state.permissions = JSON.parse(permissions);
      }
    },
    setUserId(state,Id){
      state.userId = Id
    },
    setSalesOfficerDetails(state,data){
      state.salesOfficerDetails = data
    }
  },
  plugins: [persistedState.plugin],
});

export default store;
